import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de coloració variable (del blanc, gris a rosat) en forma globosa, d’1 a 4 cm de diàmetre. Aquestes fructificacions són més o manco allargades, i presenten uns plecs irregulars que fan que es sembli a un cervell. Si es talla presenta un nucli blanc i sòlid rodejat per una massa gelatinosa, blana i elàstica. Les espores són hialines (transparents), llises, més o manco ovoides, de 8-13 x 6-9 micres. Bolet de coloració variable (del blanc, gris a rosat) en forma globosa, d’1 a 4 cm de diàmetre. Aquestes fructificacions són més o manco allargades, i presenten uns plecs irregulars que fan que es sembli a un cervell. Si es talla presenta un nucli blanc i sòlid rodejat per una massa gelatinosa, blana i elàstica. Les espores són hialines (transparents), llises, més o manco ovoides, de 8-13 x 6-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      